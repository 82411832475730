<script lang="ts" setup>
import type { RecentWinners } from "@/types";

const { t } = useT();

const props = defineProps<{
	games: RecentWinners;
	superchargedBox?: boolean;
}>();

const activeTab = ref("day");

const displayedCount = ref(true);
const displayedData = computed(() => {
	const slisedVal = displayedCount.value ? 4 : 6;
	const result = {};
	for (const key in props.games) {
		result[key] = props.games[key].slice(0, slisedVal);
	}
	return result;
});

const changeTab = (tab) => {
	activeTab.value = tab;
};

const showMore = () => {
	displayedCount.value = !displayedCount.value;
};
</script>

<template>
	<div>
		<div class="section-title" data-tid="last-winners">
			<AText variant="tlaquepaque" :modifiers="['medium']">{{ t("Look at our") }}</AText>
			<AText
				variant="teresina"
				as="span"
				gradient="var(--ghaziabad)"
				:modifiers="['bold', 'uppercase']"
				class="text-tlalnepantla"
			>
				<span>{{ t("TOP WINNERS") }}</span>
			</AText>
		</div>
		<div class="tabs">
			<AText
				v-for="(section, key) in displayedData"
				:key="key"
				as="div"
				class="tabs__item text-tlalnepantla"
				:class="{ 'tabs__item--active': activeTab === key }"
				variant="tempe"
				:modifiers="['semibold', 'uppercase']"
				@click="changeTab(key)"
			>
				<template v-if="key === 'day'">{{ t("Today") }}</template>
				<template v-if="key === 'month'">{{ t("Month") }}</template>
				<template v-if="key === 'multi'">{{ t("Mult.") }}</template>
			</AText>
		</div>
		<div class="wrapper">
			<div
				v-for="(section, key) in displayedData"
				:key="key"
				class="column"
				:class="{ 'column--active': activeTab === key }"
			>
				<AText as="div" class="column-title" variant="taipei" :data-tid="`winners-${key}`" :modifiers="['medium']">
					<template v-if="key === 'day'">{{ t("Winnings per day") }}</template>
					<template v-if="key === 'month'">{{ t("Winnings per month") }}</template>
					<template v-if="key === 'multi'">{{ t("Top X per month") }}</template>
				</AText>
				<client-only>
					<transition-group name="list-complete">
						<div v-for="(item, index) in section" :key="item.id" class="card-wrapper list-complete-item">
							<div class="card">
								<MRecentGameCard
									class="card-item"
									:item="item"
									:superchargedBox="superchargedBox"
									:data-tid="`last-winners-card-${index}`"
								/>
							</div>
						</div>
					</transition-group>
				</client-only>
			</div>
		</div>

		<div class="action" :class="{ 'action--sliced': displayedCount }">
			<AButton
				variant="primary"
				class="btn"
				size="xl"
				:data-tid="`winners-${displayedCount ? 'more' : 'less'}`"
				@click="showMore"
			>
				<AText variant="tanta" :modifiers="['semibold']" class="text-tlalnepantla">
					<template v-if="displayedCount">{{ t("Show More") }}</template>
					<template v-else>{{ t("Show Less") }}</template>
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style scoped lang="scss">
.section-title {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: gutter(4);
	gap: 0 gutter(2);
	position: relative;
	padding: 0 gutter(2);

	@include media-breakpoint-down(md) {
		margin-bottom: gutter(2);
		padding: 0 gutter(4);
	}
}
.tabs {
	display: none;
	justify-content: space-between;
	width: calc(100% - 32px);
	margin: 0 auto gutter(2);

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			display: flex;
		}
	}

	@include media-breakpoint-down(md) {
		display: flex;
	}

	&__item {
		flex-grow: 2;
		display: flex;
		justify-content: center;
		padding: gutter(2) 0;
		border-bottom: 3px solid var(--celaya);

		@include media-breakpoint-down(lg) {
			padding: gutter(1) 0;
		}

		&--active {
			color: var(--сirebon);
			border-bottom: 3px solid var(--сirebon);
		}

		&:hover {
			cursor: pointer;
		}
	}
}
.wrapper {
	max-width: calc(100% - 32px);
	width: 1240px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	gap: gutter(5);

	@include media-breakpoint-down(lg) {
		gap: gutter(2);
	}
}

.column {
	flex: 1;
	max-width: calc(33% - 30px);
	position: relative;
	@include media-breakpoint-down(lg) {
		max-width: 30%;

		@media (orientation: landscape) {
			transition: 0s;
			position: absolute;
			height: 0;
			width: 0;
			overflow: hidden;
		}
	}

	@include media-breakpoint-down(md) {
		transition: 0s;
		position: absolute;
		height: 0;
		width: 0;
		overflow: hidden;
	}

	&--active {
		animation: opacity 1s forwards;

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				position: relative;
				height: auto;
				overflow: hidden;
				display: block;
				max-width: 100%;
				width: 100%;
			}
		}

		@include media-breakpoint-down(md) {
			position: relative;
			height: auto;
			overflow: hidden;
			display: block;
			max-width: 100%;
			width: 100%;
		}
	}
}

.action {
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;

	&--sliced {
		padding: gutter(8) 0 gutter(3);
		margin-top: gutter(-18);
		background: var(--guadalupe);

		@include media-breakpoint-down(md) {
			margin-top: gutter(-12);
		}
	}
}

.btn {
	width: 280px;

	@include media-breakpoint-down(md) {
		width: 220px;
	}
}

.list-complete-item {
	width: 100%;
	transition: all 1s;
	display: inline-block;
}
.list-complete-enter, .list-complete-leave-to
	/* .list-complete-leave-active до версии 2.1.8 */ {
	opacity: 0;
	transform: translateY(30px);
}
.list-complete-leave-active {
	position: absolute;
	transition: 0s;
}

.list-width-enter-active,
.list-width-leave-active {
	width: 100%;
	position: absolute;
	transition:
		min-height 0.8s,
		height 0.8s,
		opacity 0.8s;
}
.list-width-enter-from,
.list-width-leave-to {
	opacity: 0;
	height: 0 !important;
	min-height: 0 !important;
	max-height: 0 !important;
}
.column-title {
	margin-bottom: gutter(2.5);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.event-icon {
	position: absolute;

	&.left {
		width: 135px;
		height: 151px;
		left: 250px;
		bottom: -20px;

		@include media-breakpoint-down(lg) {
			left: 20px;
		}

		@include media-breakpoint-down(md) {
			width: 67px;
			height: 75px;
			bottom: 45px;
		}
	}

	&.right {
		width: 71px;
		height: 70px;
		right: 230px;
		bottom: -15px;

		@include media-breakpoint-down(lg) {
			width: 36px;
			height: 35px;
			right: 8px;
			bottom: 36px;
		}
	}

	&.bottom {
		width: 61px;
		height: 60px;
		left: 230px;
		bottom: -70px;

		@include media-breakpoint-down(lg) {
			width: 36px;
			height: 35px;
			left: 70px;
			bottom: -40px;
		}
	}
}

@keyframes opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
